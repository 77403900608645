import { Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import "animate.css";
const CustomWhatsapp = () => {
  return (
    <Flex
      className="animate__animated animate__headShake"
      position="fixed"
      bottom="20px"
      right="25px"
      zIndex="1"
      justifyContent="center"
      alignItems="center"
      backgroundColor="green.500"
      borderRadius="full"
      padding="10px"
      cursor="pointer"
      onClick={() => {
        window.open(
          "https://api.whatsapp.com/send?phone=584242265478&text=Buenas me interesa saber sobre sus prouctos y servicios",
          "_blank"
        );
      }}
    >
      <IoLogoWhatsapp style={{ width: "40px", height: "40px" }} />
    </Flex>
  );
};

export default CustomWhatsapp;
