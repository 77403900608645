import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import CustomAccordion from "../custom/CustomAccordion";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

import bgParallax from "../../assets/images/parallax.png";

interface Condition {
  hueShift: string;
  title: JSX.Element;
  children: JSX.Element;
  filter?: string;
}

const conditions: Condition[] = [
  {
    title: <>Disponibilidad</>,
    hueShift: "",
    children: (
      <Text>
        Disponibilidad de los fondos
        <br /> los 365 días del año.
      </Text>
    ),
  },
  // {
  //   title: <>Comodidad</>,
  //   hueShift: "#bff6f970",
  //   filter: "hue-rotate(148deg);",
  //   children: (
  //     <VStack>
  //       <Text>
  //         El Titular de la Cuenta podrá contar con una tarjeta de PagoFlash con
  //         las características de una tarjeta de débito, la misma podrá ser
  //         utilizada en puntos de ventas y cajeros automáticos del Banco Nacional
  //         de Crédito, según la disponibilidad de fondo que mantenga el Cliente
  //         en la cuenta
  //       </Text>
  //     </VStack>
  //   ),
  // },
  {
    title: <>Limite Máximo</>,
    hueShift: "#ffeb009e",
    children: <Text>Sin límite de movimientos ni fondos.</Text>,
  },
  {
    title: <>Comisiones</>,
    filter: "grayscale(1)",
    hueShift: "#0000009c",
    children: (
      <div>
        <TableContainer width="100%" maxWidth="500px" mx="auto">
          <Table size="sm">
            <Thead bg="orange.500">
              <Tr>
                <Th
                  color="white"
                  width="200px"
                  whiteSpace="normal"
                  wordBreak="break-word"
                >
                  Descripción
                </Th>
                <Th
                  isNumeric
                  color="white"
                  width="200px"
                  whiteSpace="normal"
                  wordBreak="break-word"
                >
                  Tarifa
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td color="white" whiteSpace="normal" wordBreak="break-word">
                  Cuota de mantenimiento mensual cuenta transaccional
                </Td>
                <Td
                  isNumeric
                  color="white"
                  whiteSpace="normal"
                  wordBreak="break-word"
                >
                  Bs 0,05
                </Td>
              </Tr>
              <Tr>
                <Td color="white" whiteSpace="normal" wordBreak="break-word">
                  Transferencias o Pago Movil entre cuentas transaccional
                  Pagoflash Wallet
                </Td>
                <Td
                  isNumeric
                  color="white"
                  whiteSpace="normal"
                  wordBreak="break-word"
                >
                  0,5%
                </Td>
              </Tr>
              <Tr>
                <Td color="white" whiteSpace="normal" wordBreak="break-word">
                  Pago Movil o transferencia enviado al sistema bancario
                </Td>
                <Td
                  isNumeric
                  color="white"
                  whiteSpace="normal"
                  wordBreak="break-word"
                >
                  Hasta el 0,30% del monto de pago, con una comisión de Bs 0,13
                </Td>
              </Tr>
              <Tr>
                <Td color="white" whiteSpace="normal" wordBreak="break-word">
                  Operaciones rechazadas
                </Td>
                <Td
                  isNumeric
                  color="white"
                  whiteSpace="normal"
                  wordBreak="break-word"
                >
                  Bs 0,13
                </Td>
              </Tr>
              <Tr>
                <Td color="white" whiteSpace="normal" wordBreak="break-word">
                  Recarga de cuenta transaccional, con Pago Movil recibido o
                  transferencia bancaria
                </Td>
                <Td
                  isNumeric
                  color="white"
                  whiteSpace="normal"
                  wordBreak="break-word"
                >
                  No será cobrada las primeras 5 veces por usuario, y luego será
                  cobrado Bs 0,10 por recarga de Wallet
                </Td>
              </Tr>
              <Tr>
                <Td color="white" whiteSpace="normal" wordBreak="break-word">
                  Transferencias de Wallet a Wallet dentro del ecosistema
                  Pagoflash (validez 01/2025)
                </Td>
                <Td
                  isNumeric
                  color="white"
                  whiteSpace="normal"
                  wordBreak="break-word"
                >
                  sin comisión
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        <Text color="white" fontSize={"lg"} paddingLeft={8}>
          Comisiones adaptadas a tu empresa y el tipo de negocio que maneja.{" "}
        </Text>
      </div>
      // <Box textAlign="center">
      //   <Box mb="8">
      //     <Text color="teal.300">
      //       Personas
      //       <br />
      //       naturales y jurídicas
      //     </Text>
      //     <Text color="white">1 día hábil </Text>
      //   </Box>

      //   <Box mb="8">
      //     <Text color="teal.300">
      //       Abono desde
      //       <br />
      //       tarjeta de crédito
      //     </Text>
      //     <Text color="white" fontSize="3xl" fontWeight="400">
      //       2 días hábiles
      //     </Text>
      //   </Box>

      //   {/* <Text color="teal.300" fontSize="xl">
      //     *Importante: procesamos pago en Bolivares
      //   </Text> */}
      // </Box>
    ),
  },
];

const ConditionsSection = () => {
  const noWrapClass = useBreakpointValue({ base: "nowrap", lg: "" });

  return (
    <Section
      spacing="sm"
      pb="0"
      px="0"
      id="hero-bottom"
      position="relative"
      zIndex="1"
    >
      <SectionHeader color="gray.700" mb="24" textAlign="center">
        <Text as="span" color="orange.500">
          Que ofrecemos
        </Text>
      </SectionHeader>
      <CustomAccordion itemWidth={1003}>
        {conditions.map((condition, i) => (
          <Box
            w={{ base: "100%", lg: "1003px" }}
            minH="630px"
            position="relative"
            key={i}
          >
            <Box
              w="100%"
              h="100%"
              backgroundImage={`url(${bgParallax})`}
              backgroundBlendMode="overlay"
              backgroundColor={condition.hueShift}
              filter={condition.filter}
              position="absolute"
              top="0"
              left="0"
            ></Box>
            <Flex
              py="32"
              px="24"
              w="100%"
              h="100%"
              alignItems="center"
              color="white"
              position={{ base: "relative", lg: "absolute" }}
              top="0"
              left="0"
              zIndex="1"
              flexDir={{ base: "column", lg: "row" }}
            >
              <Box
                fontSize={{ base: "4xl", sm: "5xl" }}
                fontWeight={"medium"}
                maxW={{ base: "none", lg: "md" }}
                className={noWrapClass}
              >
                {condition.title}
              </Box>

              <Box
                marginLeft={{ base: "0", lg: "auto" }}
                textAlign={{ base: "center", lg: "unset" }}
                color="gray.700"
                fontSize="3xl"
                fontWeight="medium"
              >
                {condition.children}
              </Box>
            </Flex>
          </Box>
        ))}
      </CustomAccordion>
    </Section>
  );
};

export default ConditionsSection;
